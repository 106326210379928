<template>
  <div class="recommendPage">
    <swiper :options="swiperOption" ref="mySwiper">
      <swiper-slide v-for="(item, index) in list" :key="index">
        <div class="swiper-box">
          <img :src="item.url" />
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
  name: "Home",
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    list: {
      type: Array,
      default: () => "",
    },
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        notNextTick: false,
        //autoplay:false,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      },
    };
  },
  computed: {
    // swiper() {
    //   return this.$refs.mySwiper.swiper;
    // },
  },
  mounted() {
    // current swiper instance
    // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
    console.log("this is current swiper instance object", this.swiper);
    // this.swiper.slideTo(3, 1000, false);
  },
};
</script>

<style scoped>
.recommendPage {
  width: 100% !important;
  height: 100% !important;
}
.recommendPage .swiper-container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
.swiper-wrapper {
  width: 100%;
  height: 100%;
}
.recommendPage .swiper-container .swiper-slide {
  width: 100%;
  height: 100%;
  color: #000;
  font-size: 16px;
  text-align: center;
}
.recommendPage .swiper-container .swiper-slide img {

  width: 100%;

  height: 100%;
  border-radius: 0.06rem /* 6/100 */;
}
.swiper-box {

  width: 100%;

  height: 100%;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: url(../assets/img/zhSchool/gk/Pic_Bg_JXFC.png) no-repeat;
  background-size: 100% 100%;
}
</style>
<template>
  <div class="genelal-box geen">

    <div class="genelal-box-swiper">
      <dv-border-box-8 :dur="10">
        <div class="school-elegant">
          <div class="school-swiper-title">
            教学风采
            <dv-decoration-2
              style="width: 100%; height: 5px;"
              :color="['#ffffff']"
            />
          </div>
          <div class="school-elegant-swiper">
            <div
              class="school-elegant-swiper-lun"
              v-if="schoolView.length > 0"
            >
              <Swiper :list="schoolView" v-if="schoolView"/>

            </div>
            <div
              class="school-elegant-swiper-lun"
              v-else
            >
              <img
                src="../../../../assets/img/zhSchool/defalut/Pic_Ks_JXFC.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </dv-border-box-8>
    </div>

    <div class="genelal-box-info">
      <div style="width:33.3%;">
        <dv-border-box-8 :dur="10">
          <div class="school-strength-tec">

            <div class="school-strength">
              <div class="school-info-title">
                师资力量
                <dv-decoration-2
                  style="width: 100%; height: 5px"
                  :color="['#ffffff']"
                />
              </div>
              <div
                class="school-strength-box"
                v-if="wisdomSchoolTeachers.length > 0"
              >
                <swiper
                  :options="szllOption"
                  ref="mySwiper"
                >
                  <swiper-slide
                    v-for="(item, index) in wisdomSchoolTeachers"
                    :key="index"
                  >
                    <div class="school-strength-box-info">
                      <div class="tec-img">
                        <img
                          :src="item.headImg"
                          alt=""
                        />
                      </div>
                      <div class="tec-info">
                        <p class="name">
                          <span>{{ item.name }}</span>
                          <span v-if="item.education == 0">博士</span>
                          <span v-if="item.education == 1">研究生</span>
                          <span v-if="item.education == 2">本科</span>
                          <span v-if="item.education == 3">专科</span>
                          <span v-if="item.education == 4">高中</span>
                          <span v-if="item.education == 5">初中</span>
                          <span>{{ item.sex == 1 ? "男" : "女" }}</span>
                        </p>
                        <p class="technical">{{ item.major }}</p>
                        <p class="introduce">
                          {{ item.remarks }}
                        </p>
                      </div>
                    </div>
                  </swiper-slide>
                  <div
                    class="swiper-pagination"
                    slot="pagination"
                  ></div>
                </swiper>
              </div>
              <div
                class="school-strength-box"
                v-else
              >
                <div class="school-strength-box-info">
                  <img
                    src="../../../../assets/img/zhSchool/defalut/Pic_Ks_SZLL.png"
                    alt=""
                  />
                </div>
              </div>
            </div>

          </div>
        </dv-border-box-8>
      </div>
      <div style="width:33.3%;">
      <dv-border-box-8 :dur="10">
        <div class="school-idea">

        <div class="school-strength">
          <div class="school-info-title">
            教学理念
            <dv-decoration-2
              style="width: 100%; height: 5px"
              :color="['#ffffff']"
            />
          </div>
          <div class="school-idea-content">
            <div
              class="school-idea-content-txt"
              v-if="teachingConcept != ''"
            >
              {{ teachingConcept }}
            </div>
            <div
              class="school-idea-content-null"
              v-else
            >
              <p>暂无教学理念</p>
            </div>
          </div>
        </div>

      </div>
      </dv-border-box-8>
      </div>
      
      <div style="width:33.3%;">
         <dv-border-box-8 :dur="10">
           <div class="certification-con">

        <div class="school-strength">
          <div class="school-info-title">
            荣誉资质
            <dv-decoration-2
              style="width: 100%; height: 5px"
              :color="['#ffffff']"
            />
          </div>
          <div class="certification-con-list">
            <div
              class="certification-con-list-box"
              v-if="schoolHonor.length > 0"
            >
              <div
                class="button-prev"
                slot="button-prev"
              ></div>
              <swiper
                :options="ryzzOption"
                ref="mySwiperryzz"
              >
                <swiper-slide
                  v-for="(item, index) in schoolHonor"
                  :key="index"
                >
                  <div class="certification-con-list-box-swiper">
                    <img
                      :src="item.url"
                      alt=""
                    />
                  </div>
                </swiper-slide>
                <div
                  class="swiper-pagination"
                  slot="pagination"
                ></div>
              </swiper>
              <div
                class="button-next"
                slot="button-next"
              ></div>
            </div>
            <div
              class="certification-con-list-box-null"
              v-else
            >
              <img
                src="../../../../assets/img/zhSchool/defalut/Pic_Ks_RYZZ.png"
                alt=""
              />
            </div>
          </div>
        </div>

      </div>
         </dv-border-box-8>
      </div>
     

    </div>
  </div>
</template>
<script>
import Swiper from "../../../../components/swiperLunnew.vue";
//import vueSeamlessScroll from "vue-seamless-scroll";
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: {
    Swiper,
    swiper,
    swiperSlide,
    //vueSeamlessScroll,
  },
  data() {
    return {
      schoolDataInfo: "",
      szllOption: {
        loop: true,
        
        autoplay: {
          delay: 4000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // pagination: {
        //   el: ".swiper-pagination", //与slot="pagination"处 class 一致
        //   clickable: false, //轮播按钮支持点击
        // },
      },
      ryzzOption: {
        loop: true,
       
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // pagination: {
        //   el: ".swiper-pagination", //与slot="pagination"处 class 一致
        //   clickable: false, //轮播按钮支持点击
        // },
        navigation: {
          nextEl: ".button-next",
          prevEl: ".button-prev",
        },
      },
      updateList: [
        {
          url: require("../../../../assets/img/zhSchool/one/up1.png"),
        },
        {
          url: require("../../../../assets/img/zhSchool/one/up2.png"),
        },
      ],
      // 老师数据
      tecDataList: [
        {
          img: require("../../../../assets/img/zhSchool/one/tec_img1.png"),
          name: "李美玲",
          education: "本科",
          technical: "国家一级幼师",
          major: "幼儿心理健康与发展专业",
          introduce:
            "李美玲老师曾获得国家一级幼师称号，2018年入园以来培养了大批优秀幼儿",
        },
        {
          img: require("../../../../assets/img/zhSchool/one/tec_img2.png"),
          name: "张鹏鹏",
          education: "本科",
          technical: "国家一级幼师",
          major: "幼儿心理健康与发展专业",
          introduce:
            "张鹏鹏老师曾获得国家一级幼师称号，2020年入园以来培养了大批优秀幼儿",
        },
      ],
      listDataXy: [],
      schoolData: "",
      teachingConcept: "", // 简介
      wisdomSchoolTeachers: [], // 教师
      schoolHonor: [], // 荣誉资质
      schoolView: [], // 校园风采
      wisdomScreenStyleId: "", // 风格
      screenwidth: "300px",
    };
  },
  computed: {
    // swiper() {
    //   return this.$refs.mySwiper.swiper;
    // },
  },
  mounted() {
    this.init();
  },
  methods: {
    // 默认
    init() {
      //this.getSmartBaseInfo();
      this.getSmartSchoolInfo(); // AtteSchoolSmart根据ID查询学校信息
    },
    // getSmartBaseInfo() {
    //   this.api.zhSchool
    //     .getSmartBaseInfo(this.$store.state.userInfo.schoolId)
    //     .then((res) => {
    //       console.log(res.data);
    //       this.schoolDataInfo = res.data;
    //       this.listDataXy = res.data.school_XYFC;
    //       this.updateList = res.data.school_MainShow;
    //     });
    // },
    getSmartSchoolInfo() {
      this.api.zhSchool
        .schoolDetail(this.$store.state.userInfo.schoolId)
        .then((res) => {
          this.schoolData = res.data;
          this.schoolView = res.data.schoolView;
          this.teachingConcept = res.data.teachingConcept;
          this.schoolHonor = res.data.schoolHonor;
          this.wisdomScreenStyleId = res.data.wisdomScreenStyleId;
          this.wisdomSchoolTeachers = res.data.wisdomSchoolTeachers;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/schoolGk/_handle.scss";
.genelal-box {
  width: 100%;
  height: 100%;
  background: #0e1b4827;
  //border-radius: 16px;
  //padding: 15px 30px 0;
  box-sizing: border-box;
  position: relative;
}
.genelal-box-top {
  width: 1520px;
  margin-left: 63px;
}
.genelal-box-top-info {
  @include flex(row, space-between, center);
}
.genelal-box-swiper {
  width: 100%;
  height: 64%;
  box-sizing: border-box;
}
.school-elegant {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  .school-swiper-title {
    color: #ffffff;
    font-size: 22px;
    text-align: center;
  }
  .school-elegant-swiper {
    width: 100%;
    margin-top: 15px;
    height: calc(100% - 50px);
    position: absolute;
    bottom: 0px;
    left: 0;
    box-sizing: border-box;
    padding:0 40px;
    @include flex(row, center, center);
    .school-elegant-swiper-lun {
      width: 100%;
      height: 100%;
      @include flex(row, center, center);
      img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
      .recommendPage {
        width: 1343px;
        height: 100%;
        img {
          border-radius: 6px;
        }
      }
    }
  }
}
.swiper-box {
  height: 100% !important;
}
.school-idea {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 26px;
  .school-idea-content {
    width: 100%;
    padding: 18px 23px;
    background: rgba(42, 104, 134, .1);
    border-radius: 8px;
    box-sizing: border-box;
    height: calc(100% - 60px);
    margin-top: 20px;
    .school-idea-content-txt {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: white;
    }
    .school-idea-content-null {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      p {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #dddddd;
        line-height: 28p;
      }
    }
  }
}
.genelal-box-info {
  display: flex;
  //margin-top: 35px;
  justify-content: space-between;
  height: 36%;
  //position:absolute;
  //bottom:0;
  //left:0;
  //margin-left: 30px;
  overflow: hidden;
  box-sizing: border-box;
}
.school-strength-tec {
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 26px 0 23px;
  .school-strength {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    .school-strength-box {
      //width: 100%;
      padding: 18px 23px;
      background: rgba(42, 104, 134, .1);
      border-radius: 8px;
      //margin-right: 46px;
      box-sizing: border-box;
      height: calc(100% - 60px);
      margin-top: 20px;
      .school-strength-box-info {
        height: 100% !important;
        @include flex(row, space-between, center);
        img {
          width: 433px;
          height: 100%;
          border-radius: 6px;
        }
        .tec-img {
          width: auto;
          height: 100%;
          position: relative;
          img {
            width: auto;
            height: 100%;
            border-radius: 6px;
          }
          .icon-label {
            width: 25px;
            height: 142px;
            position: absolute;
            top: -5px;
            left: -4px;
          }
        }
        .tec-info {
          flex: 1;
          height: 100%;
          // background-image: url("../../../../assets/img/zhSchool/one/Pic_ZS_SZLL.png");
          // background-size: cover;
          padding: 0 20px;
          padding-left: 27px;
          .name {
            font-size: 18px;
            font-weight: 400;
            @include font_color("nameColor");
            margin-top: 5%;
            margin-bottom:5px;
            span:nth-child(2) {
              padding: 0 30px;
            }
          }
          .technical {
            font-size: 16px;
            @include font_color("nameColor2");
          }
          .introduce {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            @include font_color("txtColor");
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}
.school-strength {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  .school-strength-box {
    width: 100%;
    padding: 18px 23px;
    background: #2a688690;
    border-radius: 8px;
    // margin-right: 46px;
    box-sizing: border-box;
    height: calc(100% - 60px);
    margin-top: 20px;
    .school-strength-box-info {
      height: 100% !important;
      @include flex(row, space-between, center);
      img {
        width: 433px;
        height: 100%;
        border-radius: 6px;
      }
      .tec-img {
        width: auto;
        height: 100%;
        position: relative;
        img {
          width: auto;
          height: 100%;
          border-radius: 6px;
        }
        .icon-label {
          width: 25px;
          height: 142px;
          position: absolute;
          top: -5px;
          left: -4px;
        }
      }
      .tec-info {
        flex: 1;
        height: 100%;
        // background-image: url("../../../../assets/img/zhSchool/one/Pic_ZS_SZLL.png");
        // background-size: cover;
        padding: 0 20px;
        padding-left: 27px;
        .name {
          font-size: 18px;
          font-weight: 400;
          @include font_color("nameColor");
          margin-top: 5%;
          span:nth-child(2) {
            padding: 0 30px;
          }
        }
        .technical {
          font-size: 16px;
          @include font_color("nameColor2");
        }
        .introduce {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          @include font_color("txtColor");
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
.genelal-box-bottom {
  width: 1588px;
  display: flex;
  justify-content: space-between;
  .num-itme {
    width: 160px;
    height: 190px;
    background-image: url("../../../../assets/img/zhSchool/one/Pic_Bg_YSRS.png");
    background-size: cover;
    @include flex(row, center, center);
    flex-direction: column;
    .genelal-num {
      font-size: 36px;
      font-family: Impact;
      font-weight: 400;
      color: #ffdaa0;
      -webkit-text-stroke: 1px #d5fdff;
      text-stroke: 1px #d5fdff;
      background: linear-gradient(58deg, #64a9c6 0%, #c5f8ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .genelal-title {
      font-size: 18px;
      font-weight: 400;
      color: #dff3ff;
    }
  }
}
.certification-con {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 23px;
  width:100%;
  box-sizing: border-box;
  .certification-con-title {
    width: 313px;
    height: 52px;
    background-image: url("../../../../assets/img/zhSchool/one/Pic_Bg_Title_JXLN.png");
    background-size: cover;
    font-size: 22px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #c3e8ff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 35px;
  }
  .certification-box-scroll {
    width: 100%;
  }

  .certification-con-list {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100%;
    padding: 18px 23px;
    box-sizing: border-box;
    background: rgba(42, 104, 134, .1);
    border-radius: 8px;
    height: calc(100% - 60px);
    margin-top: 20px;
    .certification-con-list-box-null {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 210px;
        height: 143px;
      }
    }
    .certification-con-list-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      .swiper-container {
        //width: 200px !important;
        height: 100% !important;
      }
      .certification-con-list-box-swiper {
        width: auto;
        height: 100%;
        // background-image: url("../../../../assets/img/zhSchool/gk/Pic_Bk_RYZZ.png");
        // background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: auto;
          height: 100%;
          border-radius: 6px;
        }
      }
    }
  }
}
/deep/ .swiper-pagination-bullet {
  width: 7px;
  height: 2px;
  border: 1px solid #dff3ff;
  opacity: 0.3;
  border-radius: 1px;
}
/deep/ .swiper-pagination-bullet-active {
  width: 7px;
  height: 2px;
  border: 1px solid #95d8ee;
  border-radius: 1px;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-pagination-bullets {
  bottom: 20px;
}
.school-info-title {
  font-size: 22px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: white;
  align-items: center;
  justify-content: center;
  //margin-bottom: 9px;
  //padding-bottom: 10px;
}
.school-strength-box .swiper-container {
  //width: 434px;
  height: 100%;
}
.school-strength-box .swiper-container .swiper-slide {
  width: 100% !important;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.button-prev {
  width: 120px;
  height: 24px;
  background: url("../../../../assets/img/zhSchool/gk/Icon_Arrow_L.png")
    no-repeat left top;
   background-size: 100% auto;
  cursor: pointer;
}
.button-next {
  width: 120px;
  height: 24px;
  background: url("../../../../assets/img/zhSchool/gk/Icon_Arrow_R.png")
    no-repeat right top;
  background-repeat: no-repeat;
  background-size: 100% auto;
  cursor: pointer;
}
</style>
<style lang="scss">
.geen .dv-border-box-8 .border-box-content {
  padding: 20px 0 !important;
  box-sizing: border-box !important;
  display: flex;
}
.geen .dv-border-box-8{
  width:100%;
}
.border_width {
  width: 1px;
  height: 100%;
  background: #67ddf7;
  margin: 34px 0;
}
</style>
<template>
  <div class="genelal-box">
    <div class="genelal-box-swiper">
      <dv-border-box-1>
        <div class="school-elegant">
          <div class="school-swiper-title">
            教学风采
            <!-- <dv-decoration-5 style="width: 1.95rem; height: 20px" /> -->
          </div>
          <div class="school-elegant-swiper">
            <div class="school-elegant-swiper-lun" v-if="schoolView.length > 0">
              <Swiper :list="schoolView" />
            </div>
            <div class="school-elegant-swiper-lun" v-else>
              <img
                src="../../../../assets/img/zhSchool/defalut/Pic_Ks_JXFC.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </dv-border-box-1>
    </div>
    <div class="genelal-box-info">
      <div class="school-strength-tec">
        <div class="school-strength">
          <div class="school-info-title">师资力量</div>
          <dv-border-box-1>
            <div
              class="school-strength-box"
              v-if="wisdomSchoolTeachers.length > 0"
            >
              <swiper :options="szllOption" ref="mySwiper">
                <swiper-slide
                  v-for="(item, index) in wisdomSchoolTeachers"
                  :key="index"
                >
                  <div class="school-strength-box-info">
                    <div class="tec-img">
                      <img :src="item.headImg" alt="" />
                      <img
                        src="../../../../assets/img/zhSchool/one/Pic_ZS_ZP.png"
                        alt=""
                        class="icon-label"
                      />
                    </div>
                    <div class="tec-info">
                      <p class="name">
                        <span>{{ item.name }}</span>
                        <span v-if="item.education == 0">博士</span>
                        <span v-if="item.education == 1">研究生</span>
                        <span v-if="item.education == 2">本科</span>
                        <span v-if="item.education == 3">专科</span>
                        <span v-if="item.education == 4">高中</span>
                        <span v-if="item.education == 5">初中</span>
                        <span>{{ item.sex == 1 ? "男" : "女" }}</span>
                      </p>
                      <p class="technical">{{ item.major }}</p>
                      <p class="introduce">
                        {{ item.remarks }}
                      </p>
                    </div>
                  </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
              </swiper>
            </div>
            <div class="school-strength-box" v-else>
              <div class="school-strength-box-info">
                <img
                  src="../../../../assets/img/zhSchool/defalut/Pic_Ks_SZLL.png"
                  alt=""
                />
              </div>
            </div>
          </dv-border-box-1>
        </div>
      </div>

      <div class="school-idea">
        <div class="school-info-title">教学理念</div>
        <dv-border-box-1>
          <div class="school-idea-content">
            <div class="school-idea-content-txt" v-if="teachingConcept != ''">
              {{ teachingConcept }}
            </div>
            <div class="school-idea-content-null" v-else>
              <p>暂无教学理念</p>
            </div>
          </div>
        </dv-border-box-1>
      </div>

      <div class="certification-con">
        <div class="certification-con-title">荣誉资质</div>
        <dv-border-box-1>
          <div class="certification-con-list">
            <div
              class="certification-con-list-box"
              v-if="schoolHonor.length > 0"
            >
              <div class="button-prev" slot="button-prev"></div>
              <swiper :options="ryzzOption" ref="mySwiperryzz">
                <swiper-slide v-for="(item, index) in schoolHonor" :key="index">
                  <div class="certification-con-list-box-swiper">
                    <img :src="item.url" alt="" />
                  </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
              </swiper>
              <div class="button-next" slot="button-next"></div>
            </div>
            <div class="certification-con-list-box-null" v-else>
              <img
                src="../../../../assets/img/zhSchool/defalut/Pic_Ks_RYZZ.png"
                alt=""
              />
            </div>
          </div>
        </dv-border-box-1>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "../../../../components/swiperLun.vue";
//import vueSeamlessScroll from "vue-seamless-scroll";
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: {
    Swiper,
    swiper,
    swiperSlide,
    //vueSeamlessScroll,
  },
  data() {
    return {
      schoolDataInfo: "",
      szllOption: {
        loop: true,
        autoplay: {
          delay: 4000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // pagination: {
        //   el: ".swiper-pagination", //与slot="pagination"处 class 一致
        //   clickable: false, //轮播按钮支持点击
        // },
      },
      ryzzOption: {
        loop: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // pagination: {
        //   el: ".swiper-pagination", //与slot="pagination"处 class 一致
        //   clickable: false, //轮播按钮支持点击
        // },
        navigation: {
          nextEl: ".button-next",
          prevEl: ".button-prev",
        },
      },
      updateList: [
        {
          url: require("../../../../assets/img/zhSchool/one/up1.png"),
        },
        {
          url: require("../../../../assets/img/zhSchool/one/up2.png"),
        },
      ],
      // 老师数据
      tecDataList: [
        {
          img: require("../../../../assets/img/zhSchool/one/tec_img1.png"),
          name: "李美玲",
          education: "本科",
          technical: "国家一级幼师",
          major: "幼儿心理健康与发展专业",
          introduce:
            "李美玲老师曾获得国家一级幼师称号，2018年入园以来培养了大批优秀幼儿",
        },
        {
          img: require("../../../../assets/img/zhSchool/one/tec_img2.png"),
          name: "张鹏鹏",
          education: "本科",
          technical: "国家一级幼师",
          major: "幼儿心理健康与发展专业",
          introduce:
            "张鹏鹏老师曾获得国家一级幼师称号，2020年入园以来培养了大批优秀幼儿",
        },
      ],
      listDataXy: [],
      schoolData: "",
      teachingConcept: "", // 简介
      wisdomSchoolTeachers: [], // 教师
      schoolHonor: [], // 荣誉资质
      schoolView: [], // 校园风采
      wisdomScreenStyleId: "", // 风格
    };
  },
  computed: {
    // swiper() {
    //   return this.$refs.mySwiper.swiper;
    // },
  },
  mounted() {
    this.init();
  },
  methods: {
    // 默认
    init() {
      //this.getSmartBaseInfo();
      this.getSmartSchoolInfo(); // AtteSchoolSmart根据ID查询学校信息
    },
    // getSmartBaseInfo() {
    //   this.api.zhSchool
    //     .getSmartBaseInfo(this.$store.state.userInfo.schoolId)
    //     .then((res) => {
    //       console.log(res.data);
    //       this.schoolDataInfo = res.data;
    //       this.listDataXy = res.data.school_XYFC;
    //       this.updateList = res.data.school_MainShow;
    //     });
    // },
    getSmartSchoolInfo() {
      this.api.zhSchool
        .schoolDetail(this.$store.state.userInfo.schoolId)
        .then((res) => {
          this.schoolData = res.data;
          this.schoolView = res.data.schoolView;
          this.teachingConcept = res.data.teachingConcept;
          this.schoolHonor = res.data.schoolHonor;
          this.wisdomScreenStyleId = res.data.wisdomScreenStyleId;
          this.wisdomSchoolTeachers = res.data.wisdomSchoolTeachers;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/schoolGk/_handle.scss";
.genelal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.genelal-box-top {
  width: 1520px;
  margin-left: 63px;
}
.genelal-box-top-info {
  @include flex(row, space-between, center);
}
.genelal-box-swiper {
  width: 100%;
  height: 610px;
  @include background_bg("jsfcBoxBg");
  background-size: 100% 100%;
}
.school-elegant {
  display: flex;
  flex-direction: column;
  align-items: center;
  .school-swiper-title {
    width: 195px;
    height: 27px;
    @include background_bg("jsfcTitleBg");
    background-size: 100% 100%;
    @include font_color("txtColor");
    font-size: 22px;
    text-align: center;
    line-height: 27px;
    margin-top: 35px;
    margin-bottom: 20px;
  }
  .school-elegant-swiper {
    @include flex(row, center, center);
    .school-elegant-swiper-lun {
      width: 1357px;
      height: 475px;
      @include background_bg("jsfcBoxSwiperBg");
      background-size: cover;
      @include flex(row, center, center);
      img {
        width: 1343px;
        height: 461px;
        border-radius: 6px;
      }
      .recommendPage {
        width: 1343px;
        height: 461px;
        img {
          border-radius: 6px;
        }
      }
    }
  }
}
.school-idea {
  display: flex;
  flex-direction: column;
  align-items: center;
  .school-idea-content {
    width: 671px;
    height: 203px;
    @include background_bg("jxlnBoxBg");
    background-size: 100% 100%;
    padding: 50px;
    box-sizing: border-box;
    margin-top: 10px;
    .school-idea-content-txt {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #c3e8ff;
    }
    .school-idea-content-null {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      p {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #dddddd;
        line-height: 28p;
      }
    }
  }
}
.genelal-box-info {
  display: flex;
  margin-top: 35px;
}
.school-strength-tec {
  width: 503px;
  display: flex;
  justify-content: center;
  .school-strength {
    display: flex;
    flex-direction: column;
    align-items: center;
    .school-strength-box {
      width: 503px;
      height: 203px;
      @include background_bg("szllBoxBg");
      background-size: cover;
      @include flex(row, center, center);
      margin-top: 10px;
      .school-strength-box-info {
        width: 434px;
        height: 142px;
        display: flex;
        @include flex(row, space-between, center);
        img {
          width: 433px;
          height: 144px;
          padding-left: 8px;
          border-radius: 6px;
        }
        .tec-img {
          width: 111px;
          height: 131px;
          position: relative;
          img {
            width: 111px;
            height: 131px;
            padding-left: 8px;
            border-radius: 6px;
          }
          .icon-label {
            width: 25px;
            height: 142px;
            position: absolute;
            top: -5px;
            left: -4px;
          }
        }
        .tec-info {
          width: 328px;
          height: 133px;
          background-image: url("../../../../assets/img/zhSchool/one/Pic_ZS_SZLL.png");
          background-size: cover;
          padding: 0 20px;
          padding-left: 27px;
          .name {
            font-size: 18px;
            font-weight: 400;
            @include font_color("nameColor");
            margin-top: 23px;
            span:nth-child(2) {
              padding: 0 30px;
            }
          }
          .technical {
            font-size: 16px;
            @include font_color("nameColor2");
          }
          .introduce {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            @include font_color("txtColor");
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}

.genelal-box-bottom {
  width: 1588px;
  display: flex;
  justify-content: space-between;
  .num-itme {
    width: 160px;
    height: 190px;
    background-image: url("../../../../assets/img/zhSchool/one/Pic_Bg_YSRS.png");
    background-size: cover;
    @include flex(row, center, center);
    flex-direction: column;
    .genelal-num {
      font-size: 36px;
      font-family: Impact;
      font-weight: 400;
      color: #ffdaa0;
      -webkit-text-stroke: 1px #d5fdff;
      text-stroke: 1px #d5fdff;
      background: linear-gradient(58deg, #64a9c6 0%, #c5f8ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .genelal-title {
      font-size: 18px;
      font-weight: 400;
      color: #dff3ff;
    }
  }
}
.certification-con {
  display: flex;
  flex-direction: column;
  align-items: center;
  .certification-con-title {
    width: 313px;
    height: 52px;
    background-image: url("../../../../assets/img/zhSchool/one/Pic_Bg_Title_JXLN.png");
    background-size: cover;
    font-size: 22px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #c3e8ff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 35px;
  }
  .certification-box-scroll {
    width: 100%;
  }

  .certification-con-list {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 339px;
    height: 203px;
    overflow: hidden;
    @include background_bg("ryzzBoxBg");
    background-size: 100% 100%;
    padding: 0 22px;
    margin-top: 10px;
    .certification-con-list-box-null {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 210px;
        height: 143px;
      }
    }
    .certification-con-list-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .swiper-container {
        width: 200px !important;
      }
      .certification-con-list-box-swiper {
        width: 200px;
        height: 134px;
        background-image: url("../../../../assets/img/zhSchool/gk/Pic_Bk_RYZZ.png");
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 178px;
          height: 110px;
          border-radius: 6px;
        }
      }
    }
  }
}
/deep/ .swiper-pagination-bullet {
  width: 7px;
  height: 2px;
  border: 1px solid #dff3ff;
  opacity: 0.3;
  border-radius: 1px;
}
/deep/ .swiper-pagination-bullet-active {
  width: 7px;
  height: 2px;
  border: 1px solid #95d8ee;
  border-radius: 1px;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-pagination-bullets {
  bottom: 20px;
}
.school-info-title {
  width: 313px;
  height: 52px;
  background-image: url("../../../../assets/img/zhSchool/one/Pic_Bg_Title_JXLN.png");
  background-size: cover;
  font-size: 22px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #c3e8ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
}
.school-strength-box .swiper-container {
  width: 434px;
  height: 142px;
}
.school-strength-box .swiper-container .swiper-slide {
  width: 100%;
  height: 100%;
}
.button-prev {
  width: 15px;
  height: 26px;
  background-image: url("../../../../assets/img/zhSchool/gk/Icon_Arrow_Left.png");
  background-size: cover;
  cursor: pointer;
}
.button-next {
  width: 15px;
  height: 26px;
  background-image: url("../../../../assets/img/zhSchool/gk/Icon_Arrow_Right.png");
  background-size: cover;
  cursor: pointer;
}
</style>
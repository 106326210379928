<template>
  <div
    class="carousel"
    ref="carousel"
  >
    <div
      class="whole"
      v-if="list.length>2"
    >
      <div class="roll-img">
        <!-- <span class="last" @click="after" v-show="showIndex"><</span>			   -->
        <!-- 图片容器 -->
        <div>
          <ul id="ul">
            <li
              v-on:click="change($event)"
              v-for="(item, index) in list"
              :class="classes[index]"
              :key="index"
            ><img
                :src="item.url"
                alt=""
              >
              <div></div>
            </li>
          </ul>
        </div>

        <!-- <span class="next" @click="before" v-show="showIndex">></span> -->
      </div>
    </div>
    <div v-else class="wholeone">
     <swper :list="list"></swper>

    </div>
    <!-- 指示灯 -->
    <!-- <div class="list">
			
			<span class="btn" v-for="(item, index) in classes" :key="index" :class="[item == 'center' ? 'active' : '']"></span>
		</div> -->
  </div>
</template>
 
<script>
import swper from "./swiperLun.vue"
export default {
  props: {
    list: Array,
  },
  data: function () {
    return {
      showIndex: false, // 是否显示左右按钮
      imgs: ["1", "2", "3", "4", "5", "6"], // 图片库
      classes: [],
      timer: null,
    };
  },
  methods: {
    before() {
      let last = this.classes.pop();
      this.classes.unshift(last);
    },
    after() {
      let first = this.classes.shift();
      this.classes.push(first);
    },
    change(e) {
      if (e.target.parentNode.classList.contains("left")) {
        this.after();
      } else if (e.target.parentNode.classList.contains("right")) {
        this.before();
      } else {
        return false;
      }
    },
  },
  mounted() {
    var that = this;
    var classnew = [
      "left",
      "center",
      "right",
      "after1",
      "after2",
      "after3",
      "after4",
    ];
    classnew.map((i, q) => {
      this.list.map((e, index) => {
        if (q == index) {
          this.classes.push(i);
        }
      });
    });

    this.$refs.carousel.addEventListener("mouseover", function () {
      that.showIndex = true;
      clearInterval(that.timer);
    });
    this.$refs.carousel.addEventListener("mouseout", function () {
      that.showIndex = false;
      that.timer = setInterval(() => {
        that.before();
      }, 2000);
    });
    this.timer = setInterval(() => {
      this.before();
    }, 2000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  components:{
    swper
  }
};
</script>
<style lang="scss" scoped>
.carousel {
  width: 100%;
  height: 100%;
  .whole {
    width: 70%;
    height: 100%;
    margin: 0 auto;
    .roll-img {
      width: 100%;
      height: 100%;
      position: relative;
      transform-style: preserve-3d;
      ul li {
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }
  .wholeone{
  width: 100%;
    height: 100%;
    margin: 0 auto;
    .roll-img {
      width: 100%;
      height: 100%;
      position: relative;
      transform-style: preserve-3d;
      ul li {
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }
}
img {
  width: 100%;
  height: 100%;
}

.left {
  left: -30%;
  transform: scale(0.8);
  z-index: 4;
  background: rgb(0, 0, 0);
  transition: all 0.5s ease;
}
.center {
  z-index: 6;
  left: 0;
  top: 0;
  bottom: 10%;
  transition: all 0.5s ease;
}
.right {
  left: 30%;
  transform: scale(0.8);
  z-index: 4;
  background: rgb(0, 0, 0);
  transition: all 0.5s ease;
}
.left div,
.right div {
  z-index: 5;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.3s ease;
}
.after1,
.after2,
.after3,
.after4,
.after5,
.after6,
.after7,
.after8,
.after9,
.after10 {
  z-index: 3;
  left: 0;
  top: 0;
  visibility: hidden;
  transform: scale(0);
}
.last,
.next {
  position: absolute;
  z-index: 10;
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.7);
  border-radius: 50px;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
  top: 45%;
  color: rgba(255, 255, 255, 0.6);
}
.list {
  width: 30%;
  height: 5px;
  display: flex;
  margin: 0 auto;
  margin-top: 20px;
}
.btn {
  transition: all 0.3s ease;
  flex: 1;
  background: rgb(244, 244, 244);
}
.btn:not(:first-child) {
  margin-left: 20px;
}
.last {
  left: -200px;
}
.next {
  right: -200px;
}
// 指示灯
.btn.active {
  background: #f00;
}
</style>
<template>
  <div class="genelal">
 
    <component :is="currentRole" />
  </div>
</template>
<script>
// 海洋模式
import oceanGeneral from "../html/ocean/oceanGeneral.vue";
// 星空模式
import starryGeneral from "../html/starry/starryGeneral.vue";
export default {
  name:'genelal',
  components: {
    oceanGeneral,
    starryGeneral,
  },
  data() {
    return {

      currentRole:"",
      wisdomScreenStyleId: "",

      schoolDataInfo: "",
      szllOption: {
        loop: true,
        autoplay: {
          delay: 4000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // pagination: {
        //   el: ".swiper-pagination", //与slot="pagination"处 class 一致
        //   clickable: false, //轮播按钮支持点击
        // },
      },
      ryzzOption: {
        loop: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // pagination: {
        //   el: ".swiper-pagination", //与slot="pagination"处 class 一致
        //   clickable: false, //轮播按钮支持点击
        // },
        navigation: {
          nextEl: ".button-next",
          prevEl: ".button-prev",
        },
      },
      updateList: [
        {
          url: require("../../../assets/img/zhSchool/one/up1.png"),
        },
        {
          url: require("../../../assets/img/zhSchool/one/up2.png"),
        },
      ],
      // 老师数据
      tecDataList: [
        {
          img: require("../../../assets/img/zhSchool/one/tec_img1.png"),
          name: "李美玲",
          education: "本科",
          technical: "国家一级幼师",
          major: "幼儿心理健康与发展专业",
          introduce:
            "李美玲老师曾获得国家一级幼师称号，2018年入园以来培养了大批优秀幼儿",
        },
        {
          img: require("../../../assets/img/zhSchool/one/tec_img2.png"),
          name: "张鹏鹏",
          education: "本科",
          technical: "国家一级幼师",
          major: "幼儿心理健康与发展专业",
          introduce:
            "张鹏鹏老师曾获得国家一级幼师称号，2020年入园以来培养了大批优秀幼儿",
        },
      ],
      listDataXy: [],
      schoolData: "",
      teachingConcept: "", // 简介
      wisdomSchoolTeachers: [], // 教师
      schoolHonor: [], // 荣誉资质
      schoolView: [], // 校园风采
     //wisdomScreenStyleId: "", // 风格

    };
  },
  computed: {
    // swiper() {
    //   return this.$refs.mySwiper.swiper;
    // },
  },
  mounted() {
    // 获取默认智慧风格
    this.getStyle();
  },
  methods: {
    getStyle() {
      this.api.zhSchool
        .schoolDetail(this.$store.state.userInfo.schoolId)
        .then((res) => {

          if (res.errCode == 0) {
            if (res.data.wisdomScreenStyleId == 1) {
              this.currentRole = "starryGeneral";
              
            } else if (res.data.wisdomScreenStyleId == 2) {
              this.currentRole = "oceanGeneral";
               
            }else{
                this.currentRole = "starryGeneral";
            }
          }
         

        });
    },
  },
};
</script>
<style>
.genelal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
</style>
